import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import toastConfig from "../utilities/toastConfig";
import { SET, useActions, useStore } from "../context";
import { parse, stringify } from "qs";

axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded; charset=utf-8";

export const constants = {
	env: "1",
	app: "1030",
	sid: sessionStorage.getItem("sid") || "Promotion",
};

export default () => {
	const { developmentMode } = useStore();
	const action = useActions();

	useEffect(() => {
		action({
			type: SET,
			path: "sid",
			payload: developmentMode ? "Promotion" : sessionStorage.getItem("sid"),
		});

		axios.interceptors.request.use((config) => {
			const { method, params, data } = config;
			const isFormData = data instanceof FormData;

			if (isFormData)
				Object.keys(constants).map((key) => {
					data.append(key, constants[key]);
				});

			return {
				...config,
				params: {
					...(method === "get" ? constants : {}),
					...params,
				},
				data:
					method === "post" && !isFormData
						? stringify({
								...constants,
								...parse(data),
						  })
						: data,
			};
		});

		axios.interceptors.response.use(
			(response) => response,
			function (error) {
				const message = error?.response?.data?.message;
				toast.error(message || error.toString(), toastConfig);
				return Promise.reject(error);
			}
		);
	}, []);
	return null;
};
