import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "../components/Loading";
import routes from "./routes";

export default function Router() {
	return (
		<Suspense fallback={<Loading />}>
			<Switch>
				{routes.map((route, key) => (
					<Route key={key} {...route} />
				))}
			</Switch>
		</Suspense>
	);
}
