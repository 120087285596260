import React from "react";
import Error from "../pages/Error";
import Home from "../pages/Home";

const Track = React.lazy(() => import("../pages/Track"));
const Post = React.lazy(() => import("../pages/Post"));

export default [
	{
		path: "/",
		exact: true,
		component: Home,
	},
	{
		path: "/track",
		exact: true,
		component: Track,
	},
	{
		exact: true,
		path: "/post/:id",
		component: Post,
	},
	{
		exact: true,
		path: "/post/track/:CLMREF/:CSTEML/:CSTPHN",
		component: Post,
	},
	{
		exact: true,
		path: "/post/track/:CLMREF",
		component: Post,
	},
	{
		path: "/error",
		component: Error,
	},
	{
		path: "*",
		component: Error,
	},
];
