import React, { useEffect } from "react";
import { StoreProvider } from "./context";
import Router from "./routes/Router";
import AOS from "aos";
import Interceptor from "./core/Interceptor";
import ModalContainer from "./components/ModalContainer";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider, QueryClient } from "react-query";
import { HashRouter } from "react-router-dom";

import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/tailwind.min.css";
import "./assets/styles/custom.scss";
import "react-medium-image-zoom/dist/styles.css";
import "react-tabs/style/react-tabs.css";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: 0,
		},
	},
});

function App() {
	let initial = {
		developmentMode: process.env.NODE_ENV !== "production",
		refetchGetAttachments: 0,
		refetchGetProdUpd: 0,
		refetchGetProdDtls: 0,
		refetchGetApprDtls: 0,
		refetchGetApprUpd: 0,
		privileged: false,
		searchState: {
			CLMREF: "",
			CSTEML: "",
			CSTPHN: "",
			CSTNAM: "",
			GENSCH: "",
			pending: false,
			activePromo: null,
			page: 0,
			limit: 10,
		},
	};
	initial.sid = initial.developmentMode ? "Promotion" : sessionStorage.getItem("sid");

	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);

	return (
		<div className="App">
			<QueryClientProvider client={queryClient}>
				<StoreProvider value={initial}>
					<ToastContainer
						autoClose={2000}
						// theme="colored"
						position="bottom-left"
						progressClassName="h-1"
						toastClassName="rounded-md p-3 font-semibold text-sm leading-4"
					/>
					<Interceptor />
					<HashRouter>
						<Router />
						<ModalContainer />
					</HashRouter>
				</StoreProvider>
			</QueryClientProvider>
		</div>
	);
}

export default App;
