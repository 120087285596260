import { filter } from "lodash";
import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/smeg.svg";

export const links = [
	{
		to: "/",
		label: "Home",
		align: "left",
	},
	{
		to: "/track",
		label: "Track My Redemption",
		align: "right",
	},
];

export default function Menu() {
	return (
		<>
			<div className="flex-col items-center justify-start order-1 flex md:flex-row md:justify-end w-auto md:order-none md:flex-1 -ml-5 xs:ml-0">
				{filter(links, { align: "left" }).map(({ to, label }, key) => (
					<NavLink
						key={key}
						to={to}
						className="px-5 py-2 text-sm font-medium text-gray-600 dark:text-gray-400 hover:text-blue-500"
					>
						<span> {label} </span>
					</NavLink>
				))}
			</div>

			<div className="flex items-center justify-between xs:w-auto w-full mr-5 md:mr-0">
				<a className="w-28 dark:hidden" href="http://smeg.com.au">
					<span>
						<img src={logo} />
					</span>
				</a>
			</div>

			<div className="flex-col items-center justify-start order-2 flex md:flex-row w-auto md:flex-1 md:order-none">
				{filter(links, { align: "right" }).map(({ to, label }, key) => (
					<NavLink
						key={key}
						to={to}
						className="px-5 py-2 text-sm font-medium text-gray-600 dark:text-gray-400 hover:text-blue-500"
					>
						<span className="whitespace-nowrap"> {label} </span>
					</NavLink>
				))}
			</div>
		</>
	);
}
