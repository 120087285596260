import React from "react";
import { css } from "@emotion/core";
import { PuffLoader } from "react-spinners";

const override = css`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const Loading = () => {
	return (
		<div className="bg-white bg-opacity-75 absolute left-0 right-0 top-0 bottom-0">
			<PuffLoader className="flex items-center" css={override} size={50} color={"#5415a5"} loading />
		</div>
	);
};

export default Loading;
