import React from "react";
import moment from "moment";
import placeholder from "../../../assets/images/no-image.png";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";

export default function Card({ first, data }) {
	const { PRMCOD, PRMDSC, EFFD, EXPD, IMG, TEXT } = data;
	const text = document.createElement("span");
	text.innerHTML = TEXT;
	return (
		<div className="cursor-pointer group">
			<NavLink to={`/post/${PRMCOD}`}>
				<div
					className={`relative overflow-hidden transition-all bg-gray-100 dark:bg-gray-800 ${
						first ? "hover:scale-[1.02] aspect-[10/3]" : "hover:scale-105 aspect-square"
					}`}
				>
					<span className="box-border block overflow-hidden w-[initial] h-initial bg-none opacity-100 border-0 m-0 p-0 absolute top-0 left-0 bottom-0 right-0">
						<img
							src={IMG || placeholder}
							className="transition-all absolute inset-0 box-border p-0 border-[medium+none] m-auto block w-0 h-0 min-w-full max-w-full min-h-full max-h-full object-contain bg-black"
						/>
					</span>
				</div>

				<span className="flex items-center mt-4 text-xs font-medium tracking-wider uppercase text-rose-600 space-x-2">
					<span className="text-sm text-gray-400">{moment(EFFD).format("DD/MM/YYYY")}</span>
					<IconArrowNarrowRight className="w-5 text-rose-300 dark:text-rose-600" />
					<span className="text-sm">{moment(EXPD).format("DD/MM/YYYY")}</span>
					<span className="text-sm text-black pl-20">{PRMCOD}</span>
				</span>
				<h2 className="mt-1 text-lg font-semibold tracking-normal text-brand-primary dark:text-white">
					<span className=" dark:from-purple-800 dark:to-purple-900 bg-[length:0px_10px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_10px]">
						{PRMDSC}
					</span>
				</h2>
				{/* <div
					className="mt-1 space-x-3 text-gray-500 dark:text-gray-400"
					// dangerouslySetInnerHTML={{ __html: TEXT }}
				>
					{text.textContent || text.innerText}
				</div> */}
			</NavLink>
		</div>
	);
}
