import { SET, ADD, REMOVE, UPDATE, MODAL, REFRESH } from "./actions";
import removeEmptySlots from "./utilities/removeEmptySlots";
import merge from "lodash/merge";
import update from "lodash/update";
import unset from "lodash/unset";
import set from "lodash/set";
import get from "lodash/get";

export default function reducers(state = [], action) {
	const newState = { ...state };
	const { type, path, payload } = action;

	switch (type) {
		case SET:
			if (path) {
				set(newState, path, payload);
				return newState;
			} else return payload;

		case ADD:
			var prev = get(newState, path);
			var next = action.prepend ? [payload, ...prev] : [...prev, payload];
			set(newState, path, next);
			return newState;

		case REMOVE:
			unset(newState, path);
			return removeEmptySlots(newState, path);

		case UPDATE:
			return update(newState, path, (item) => merge(item, payload));

		case MODAL:
			if (payload.visible === undefined) payload.visible = true;
			return Object.keys(payload).length === 1 && payload.visible === false
				? { ...newState, modal: { ...newState?.modal, ...payload } }
				: { ...newState, modal: payload };

		case REFRESH:
			return merge(newState, { refresh: Math.random() });

		default:
			return state;
	}
}
