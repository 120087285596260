import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Menu from "./Menu";
// import AOS from "aos";

export default function Wrapper({ children }) {
	return (
		<div className="antialiased text-gray-800 dark:bg-black dark:text-gray-400 flex flex-col min-h-screen">
			<div className="grow">
				<div className="w-full px-4 lg:px-8 py-5 lg:py-8 xl:px-5 sticky top-0 z-10 bg-white">
					{/* backdrop-blur backdrop-brightness-115 border-b-2 border-white  shadow-lg */}
					<nav>
						<div className="flex flex-wrap md:justify-between gap-y-5 md:gap-10 xs:flex-nowrap">
							<Menu />
						</div>
					</nav>
				</div>
				<div>
					<div
						className="container px-4 lg:px-8 py-5 lg:py-8 mx-auto xl:px-5 max-w-screen-xl !pt-0"
						// className="flex-1 flex-col relative z-0 overflow-y-auto"
						// style={{ scrollbarGutter: "stable" }}
						// onScroll={() => {
						// 	AOS.refresh();
						// }}
					>
						{children}
					</div>
				</div>
			</div>

			{/* container max-w-screen-xl */}
			<div className="w-full px-4 lg:px-8 py-5 lg:py-8 mx-auto xl:px-5 mt-5 border-t-8 border-black bg-red-700 text-red-100 flex gap-10 justify-center text-sm text-center font-semibold tracking-wide flex-col md:flex-row">
				{/* <NavLink to="/policy">Privacy Policy</NavLink>
				<NavLink to="/terms">Terms & Conditions</NavLink> */}
				<a href="https://www.smeg.com/au/privacy-policy" target="_blank" rel="noreferrer">
					Privacy Policy
				</a>
				<div>SmegAustralia.com.au</div>
				<div>Copyright © 2023 Smeg. All rights reserved.</div>
			</div>
		</div>
	);
}
