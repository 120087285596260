import React from "react";
import Rodal from "rodal";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/core";

function Loading(props) {
	const { visible, options } = props;

	return (
		<Rodal
			className="flex-rodal"
			visible={visible}
			onClose={() => {}}
			showCloseButton={false}
			closeMaskOnClick={false}
			closeOnEsc={false}
			customStyles={{ minWidth: "auto" }}
			{...options}
		>
			<div
				className="p-5"
				style={{
					background: "#fff",
					borderRadius: 15,
					boxShadow: "0 1px 3px rgba(0,0,0,.2)",
				}}
			>
				<PuffLoader
					css={css`
						display: block;
					`}
				/>
			</div>
		</Rodal>
	);
}

export default Loading;
