import React, { useEffect } from "react";
import Wrapper from "../../layouts/Wrapper";
import { VVCALL_URL } from "../../services/urls";
import { useQuery } from "react-query";
import axios from "axios";
import { SET, useActions, useStore } from "../../context";
// import TrackForm from "../Track/views/TrackForm";
import Card from "./screens/Card";
import { PuffLoader } from "react-spinners";
import background from "../../assets/images/bg.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IconSquareRoundedArrowLeftFilled, IconSquareRoundedArrowRightFilled } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
const NextArrow = ({ currentSlide, slideCount, ...props }) => (
	<button
		{...props}
		className={`${props.className} group block disabled:text-opacity-25 text-white text-opacity-80 hover:text-white focus:text-white before:hidden -mt-1 right-4 z-10 !w-8 !h-8`}
		disabled={props.className.includes("slick-disabled")}
	>
		<IconSquareRoundedArrowRightFilled size={28} />
	</button>
);
// eslint-disable-next-line no-unused-vars
const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
	<button
		{...props}
		className={`${props.className} group block disabled:text-opacity-25 text-white text-opacity-80 hover:text-white focus:text-white before:hidden -mt-1 left-4 z-10 !w-8 !h-8`}
		disabled={props.className.includes("slick-disabled")}
	>
		<IconSquareRoundedArrowLeftFilled size={28} />
	</button>
);

function Home() {
	const action = useActions();
	const { promos } = useStore();
	const {
		data: getPromos,
		isFetching,
		isLoading,
	} = useQuery(
		["getPromos"],
		() =>
			axios.get(VVCALL_URL, {
				params: {
					pgm: "ORH110",
					action: "getPromos",
				},
			}),
		{
			keepPreviousData: true,
			staleTime: Infinity,
		}
	);
	useEffect(() => {
		if (getPromos?.data?.promos) {
			action({
				type: SET,
				path: "promos",
				payload: getPromos.data.promos,
			});
			action({
				type: SET,
				path: "privileged",
				payload: getPromos.data.PRIVILEGED === "1",
			});
		}
	}, [getPromos?.data?.promos]);

	if (isFetching || isLoading || !promos) {
		return (
			<Wrapper>
				<div className="flex h-40">
					<div className="inline-flex items-center mx-auto">
						<PuffLoader size={40} />
					</div>
				</div>
			</Wrapper>
		);
	} else {
		// const first = promos.shift();
		return (
			<Wrapper>
				<div>
					<div className="hidden sm:block">
						<Slider
							{...{
								nextArrow: <NextArrow />,
								prevArrow: <PrevArrow />,
								infinite: true,
								speed: 500,
								slidesToShow: 1,
								slidesToScroll: 1,
								autoplay: true,
								autoplaySpeed: 7000,
							}}
						>
							{Array.from({ length: getPromos?.data?.banner.length }, (_, index) => (
								<NavLink key={index} to={getPromos?.data?.banner[index].LNK}>
									{/* Add an onClick handler to open the URL in a new tab */}
									<div
										className="relative overflow-hidden transition-all bg-gray-100 dark:bg-gray-800 hover:opacity-90 aspect-[30/5]"
										// onClick={() => window.open(getPromos?.data?.banner[index].LNK, "_blank")}
									>
										<span className="box-border block overflow-hidden w-[initial] h-initial bg-none opacity-100 border-0 m-0 p-0 absolute top-0 left-0 bottom-0 right-0">
											<img
												src={getPromos?.data?.banner[index]?.BNR}
												className="transition-all absolute inset-0 box-border p-0 border-[medium+none] m-auto block w-0 h-0 min-w-full max-w-full min-h-full max-h-full object-cover"
											/>
										</span>
									</div>
								</NavLink>
							))}
						</Slider>
					</div>

					{/* <TrackForm /> */}

					<h1 className="bg-black text-red-600 mt-14 mb-8 text-xl font-semibold text-center uppercase tracking-widest py-1">
						Promotions
					</h1>

					<div className="grid gap-10 lg:gap-10 md:grid-cols-2 lg:grid-cols-3 mt-10">
						{promos.map((item, key) => (
							<Card key={key} data={item} />
						))}
					</div>
				</div>
			</Wrapper>
		);
	}
}

export default Home;
