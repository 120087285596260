import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import blank_canvas from "../../assets/images/undraw_blank_canvas_re_2hwy.svg";
import { VVCALL_URL } from "../../services/urls";

export default function Error({ location, match }) {
	const { status, message } =
		location.state ||
		(match.path === "*" && { status: 404, message: "The requested page could not be found" }) ||
		{};

	return (
		<div className="flex flex-col justify-center items-center flex-wrap content-center h-screen">
			<div className="text-gray-700">
				<img className="mx-auto max-w-xs" src={blank_canvas} alt="Error page" />
				<p className="text-center font-bold text-4xl mt-8">
					{!status ? "Error" : status === 401 ? "Unauthorized Access" : status}
				</p>
				<p className="text-lg text-center">{message ? message : "An error occurred !"}</p>
				<div className="flex justify-center items-center mt-4">
					{status === 401 ? (
						<button
							className="bg-red-600 text-white font-bold px-8 py-2 rounded-full hover:bg-red-700"
							onClick={() => {
								axios.post(VVCALL_URL, {
									action: "logAppClose",
									lang: "en",
									env: "1",
								});
							}}
						>
							Back To Home
						</button>
					) : (
						<Link
							to="/"
							className="bg-blue-600 text-white font-bold px-8 py-2 rounded-full hover:bg-blue-700"
						>
							Back To Home
						</Link>
					)}
				</div>
			</div>
		</div>
	);
}
