import React from "react";
import Rodal from "rodal";
import { IconTrophy, IconUrgent } from "@tabler/icons-react";

function Ask(props) {
	const {
		visible,
		options,
		title,
		description,
		closeAction,
		onClose,
		onCancel,
		onAccept,
		cancelText,
		acceptText,
		closeOnCancel,
		closeOnAccept,
		customStyles,
		noTheme,
		color,
		icon,
	} = props;

	const colorObj = {
		iconBg: color === "green" ? "bg-green-100" : "bg-blue-100",
		iconFill: color === "green" ? "text-green-600" : "text-blue-600",
		btn:
			color === "green"
				? "bg-green-600 text-white hover:bg-green-700 focus:ring-green-500"
				: "bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500",
	};

	const Icon = icon || (color === "green" ? IconTrophy : IconUrgent);

	return (
		<Rodal
			className="flex-rodal"
			visible={visible}
			onClose={() => {
				if (onClose) onClose();
				closeAction();
			}}
			customStyles={customStyles}
			{...options}
		>
			{/* rodal-content */}
			<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
				<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
					<div className="sm:flex sm:items-start">
						<div
							className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 ${colorObj.iconBg}`}
						>
							<Icon strokeWidth={1.5} className={`h-6 w-6 ${colorObj.iconFill}`} />
						</div>
						<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
							{title && (
								<h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
									{title}
								</h3>
							)}
							{description && (
								<div className="mt-2">
									<p className="text-sm text-gray-500">{description}</p>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
					{(onAccept || closeOnAccept) && (
						<button
							type="button"
							className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${colorObj.btn}`}
							onClick={() => {
								if (onAccept) onAccept();
								if (closeOnAccept) closeAction();
							}}
						>
							{acceptText || "OK"}
						</button>
					)}

					{((!onAccept && !closeOnAccept) || onCancel || closeOnCancel) && (
						<button
							type="button"
							className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
							onClick={() => {
								if (onCancel) onCancel();
								if (closeOnCancel) closeAction();
							}}
						>
							{cancelText || "Close"}
						</button>
					)}

					{/* {options && options.showCloseButton === false && !onCancel && !onAccept && (
						<button
							className={`btn-clean btn-ahref btn-block font-weight-medium p-3 divider-top`}
							onClick={onClose || closeAction}
						>
							{acceptText || "Close"}
						</button>
					)}

					{!onCancel && onAccept && (
						<button
							className={`btn-clean btn-ahref btn-block font-weight-medium p-3 divider-top`}
							onClick={onAccept}
						>
							{acceptText || "OK"}
						</button>
					)} */}
				</div>
			</div>
		</Rodal>
	);
}

export default Ask;
